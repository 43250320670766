
import React from 'react'
//import {useTheme} from '../components/Theming'
import Container from '../components/Container'
import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/Layout'
import Link from '../components/Link'
import {css} from '@emotion/core'
const Impressum = () => {
   
    const data = useStaticQuery(graphql`
    query {
        site {
          ...site
          siteMetadata {
            title
          }
        }
    }
    `)
    return(
        <Layout site={data.site}>


        <Container  css={css`
         font-size:1rem;
         p strong {
           font-weight:600;
         }
       
        `}>
          <h1 style={{fontSize:'1.4rem'}}>
        Kulturraum Markt 1
     </h1>
     <p>verantwortlich:<br/> Verschönerungsverein Bacharach 1873 e.V.<br/>
     55422 Bacharach // Marktstraße 3<br/>
Datenschutzbeauftragte: <br/>Katrin Gloggengießer<br/>
kontakt[at]markt1-bacharach.de<br/>
<br/>
<p>PROGRAMMIERUNG: Rene Broich<br/>
  KONZEPT &amp; DESIGN: Katrin Gloggengießer
</p>

<Link to= "/datenschutz">&rarr; Datenschutzbestimmungen</Link>
     </p>

        </Container>
        </Layout>

    )
}

export default Impressum
